import(/* webpackMode: "eager", webpackExports: ["FrontendConfigurationProvider"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/providers/PreviewDataProvider/PreviewDataProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/providers/UaApolloProvider/UaApolloProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/providers/UaSessionProvider/UaSessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/next/font/local/target.css?{\"path\":\"lib/client-only/local-fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/NeuePlakUA-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/NeuePlakUA-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/NeuePlakUA-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/NeuePlakUA-CompBlack.woff2\",\"weight\":\"800 900\",\"style\":\"normal\"}],\"fallback\":[\"ARMOUR\",\"Helvetica\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-base\"}],\"variableName\":\"NeuePlakUA\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/next/font/local/target.css?{\"path\":\"lib/client-only/local-fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/NeuePlakUA-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/NeuePlakUAText-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/NeuePlakUAText-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"fallback\":[\"ARMOUR\",\"Helvetica\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-text\"}],\"variableName\":\"NeuePlakUAText\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/dialog-polyfill/dist/dialog-polyfill.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/main.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/base.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/theme-cms.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/forms.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/utilities.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/@ua-digital-commerce/ua-web-components/dist/styles/base.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/variables.module.scss");
